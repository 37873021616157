import { MAILING_API } from 'shared/components/dashboard-mail-editor/api/email-api'
import { EmailOptionsInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'
import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

export const useEmailOptions = (shouldFetch: boolean = true) => {
  const { user } = useUser()

  const { data, mutate } = useSWR<EmailOptionsInterface>(
    () => user && shouldFetch && `${MAILING_API}/options`,
    baseFetcher,
  )

  return { data, mutate }
}
