import { Editor } from '@ckeditor/ckeditor5-core'
import { CKEditor as ReactCKEditor } from '@ckeditor/ckeditor5-react'
import { EventInfo } from '@ckeditor/ckeditor5-utils'
import { default as SioCKEditor } from '@systemeio/ckeditor'
import React, { useEffect, useRef, useState } from 'react'
import { SubstituteInterface } from 'shared/components/dashboard-mail-editor/components/substitutes-block'
import { CkeditorPluginsEnum } from 'shared/components/dashboard-mail-editor/enums/ckeditor-plugins-enum'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import { useSubstitutes } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { SetCkeditorRefType } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import { getLanguageCodeByLocale } from 'shared/enums/language-code-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { twMerge } from 'tailwind-merge'

interface CKEditorSkeletonProps extends Pick<CKEditorProps, 'showFooter' | 'classNameSkeleton'> {}

function CKEditorSkeleton({ showFooter, classNameSkeleton }: CKEditorSkeletonProps) {
  const { data: options } = useEmailOptions(!!showFooter)
  return (
    <div
      className={twMerge(
        `animate-pulse ${
          options ? 'rounded-t-lg' : 'rounded-lg'
        } h-[540px] bg-gray-600/40 text-transparent ${classNameSkeleton}`,
      )}
    >
      .
    </div>
  )
}

interface CKEditorProps {
  isEditable?: boolean
  onChange: (data: string) => void
  isPreFetching?: boolean
  initValue?: string
  isError?: boolean
  excludePlugins?: CkeditorPluginsEnum[]
  classNameWrapper?: string
  showFooter?: boolean
  setCkeditorRef?: SetCkeditorRefType
  additionalSubstitutes?: SubstituteInterface[]
  withoutSubstitutes?: boolean
  classNameSkeleton?: string
}

type CkeditorRefType = {
  ReactCKEditor: typeof ReactCKEditor
  SioCKEditor: typeof SioCKEditor
}

const baseExcludePlugins = [
  CkeditorPluginsEnum.blockquote,
  CkeditorPluginsEnum.table,
  CkeditorPluginsEnum.tableToolbar,
]

export default function CKEditor({
  isEditable = true,
  onChange,
  isPreFetching,
  initValue,
  isError,
  excludePlugins = baseExcludePlugins,
  showFooter,
  classNameWrapper,
  setCkeditorRef,
  additionalSubstitutes = [],
  withoutSubstitutes,
  classNameSkeleton,
}: CKEditorProps) {
  const editorRef = useRef<CkeditorRefType>()
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { ReactCKEditor, SioCKEditor } = editorRef.current || ({} as CkeditorRefType)
  const { user } = useUser()
  const { data: options } = useEmailOptions(!!showFooter)
  const locale = useRef<LocaleEnum>(LocaleEnum.ENGLISH)

  const { t } = useLocoTranslation()

  const { substitutionData, isReady } = useSubstitutes(withoutSubstitutes, additionalSubstitutes)

  const reactEditorRef = useRef<ReactCKEditor<Editor>>(null)

  useEffect(() => {
    editorRef.current = {
      ReactCKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      SioCKEditor: require('@systemeio/ckeditor'),
    }
  }, [])

  useEffect(() => {
    if (user && user.dashboardLocale !== LocaleEnum.ENGLISH) {
      locale.current = require(`@systemeio/ckeditor/build/translations/${getLanguageCodeByLocale(
        user.dashboardLocale,
      )}.js`)
    }
  }, [user])

  useEffect(() => {
    if (!isReady) return
    setEditorLoaded(true)
  }, [isReady])

  if (reactEditorRef.current && setCkeditorRef) {
    setCkeditorRef(reactEditorRef.current)
  }

  return (
    <div
      className={`main-transition-colors${
        isError ? 'border border-danger rounded-lg' : 'border border-transparent rounded-lg'
      } ${classNameWrapper || ''}`}
    >
      {editorLoaded && user ? (
        <ReactCKEditor
          ref={reactEditorRef}
          id={'sio-ckeditor'}
          // @ts-ignore
          editor={SioCKEditor}
          data={initValue || ''}
          onChange={(event: EventInfo, editor: Editor) => onChange(editor.data.get())}
          disabled={!isEditable || isPreFetching}
          config={{
            language: user.dashboardLocale,
            plugins: SioCKEditor?.builtinPlugins.filter(
              plugin => !excludePlugins.includes(plugin.pluginName),
            ),
            // @ts-ignore
            substitutionOptions: {
              disabled: withoutSubstitutes,
              substitutionData,
              title: t('dashboard.email.substitute_variables'),
            },
          }}
          onBlur={() => {}}
          onError={() => {}}
          onFocus={() => {}}
          onReady={() => {}}
          watchdogConfig={{}}
        />
      ) : (
        <CKEditorSkeleton showFooter={showFooter} classNameSkeleton={classNameSkeleton} />
      )}
      {showFooter && options && (
        <div className={'p-5 border border-gray/30 rounded-b-lg border-t-transparent bg-blue-100'}>
          {options.emailFooter}
        </div>
      )}
    </div>
  )
}

export const EmailSubstitutesSkeleton = () => (
  <div className={'flex flex-col gap-3 animate-pulse'}>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
    <div className="bg-gray-600 rounded text-transparent">.</div>
  </div>
)
